






































import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { CMS } from "../models";
import { $config, $cms, $cockpit } from '../services';

import Toolbar from '../components/Toolbar.vue';
import cfg from '../../package.json';

@Component({
  components: {
    Toolbar, 
  }
})
export default class Diagnostics extends Vue {

  version = cfg.version;

  get config(){
    return $config.store.config;
  }

  get diagnostics(): string[]{
    return $cockpit.diagnostics;
  }

  themeTertiary(theme) {
    return this.config.themes[theme].tertiary;
  }

  beforeRouteEnter(to: Route, from: Route, next: any) {
    next()
  }

  mounted(){
    document.body.classList.add('body-scroll');
  }

  beforeDestroy() {
    document.body.classList.remove('body-scroll');
  }

  async onBack() {
    this.$router.go(-1);
  }

}
